<template>
  <el-tooltip
    v-bind="{ ...$attrs, ...props }"
    effect="dark"
    :popper-class="props.popperClass + ' ui-tooltip'"
  >
    <template v-if="$slots.content" #content>
      <slot name="content"></slot>
    </template>
    <template #default>
      <slot name="default"></slot>
    </template>
  </el-tooltip>
</template>
<script lang="ts" setup>
import { useTooltipProps } from 'element-plus'
const props = defineProps({
  ...useTooltipProps,
  /**
   * Override ElTooltip showAfter prop with default value of 300ms
   */
  showAfter: {
    type: Number,
    default: 300,
  },
})
</script>
<!-- eslint-disable vue/enforce-style-attribute -->
<style lang="postcss">
.ui-tooltip.el-popper {
  --el-popper-border-radius: 0.625rem; /* 10px */
  --ui-tooltip-max-width: 264px;

  max-width: var(--ui-tooltip-max-width);
  padding: 0.625rem; /* 10px */
  font-family: var(--font-family-primary);
  font-size: 0.75rem; /* 12px */
  font-weight: 400;
  line-height: 1rem; /* 16px */
  color: var(--brand-color-text-tertiary);
  background: var(--brand-color-base-tertiary);
  border: none;
  box-shadow: none;
  & .el-popper__arrow::before {
    background: var(--brand-color-base-tertiary);
    border: none;
    border-radius: 0;
  }
  & span {
    display: block;
  }
}
</style>
